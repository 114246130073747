import { PlayIcon } from "@heroicons/react/outline"
import classNames from "classnames"
import React, { ReactElement, useRef } from "react"
import Button from "../Button"
import { transformDateFromISO } from "../../../utils/date"

export interface VideoCardProps {
  id: string
  title: string
  videoType: string
  headerImage: string
  videolink?: string
  description: string
  authorImage: string
  authorName: string
  date: string /// date uses the ISO format
  imageSize?: "small" | "medium" | "large"
}

function VideoCard({
  id,
  title,
  videoType,
  description,
  authorImage,
  headerImage,
  authorName,
  videolink,
  date,
  imageSize = "medium",
}: VideoCardProps): ReactElement {
  const anchor = useRef<HTMLAnchorElement>()
  const computeImageSize = () => {
    switch (imageSize) {
      case "small":
        return "h-48"
      case "medium":
        return "h-52"
      case "large":
        return "h-64"
      default:
        return
    }
  }

  const dateString = transformDateFromISO(date)
  return (
    <div id={id} className="rounded-md bg-white-default">
      <div
        className={classNames(
          "header_image bg-cover rounded-t-md",
          computeImageSize()
        )}
        style={{
          backgroundImage: `url(${headerImage})`,
        }}
      ></div>
      <div className="relative p-8">
        <div className="absolute -top-10 right-8">
          <a
            ref={anchor}
            href={videolink}
            target="_blank"
            rel="noreferrer nopener"
          >
            <Button
              onClick={() => {
                anchor.current.click()
              }}
              primary
              className="rounded-full h-20 w-20 px-2 py-2"
            >
              <PlayIcon className="text-6xl"></PlayIcon>
            </Button>
          </a>
        </div>
        <span className="text-brand-700 font-bold">{videoType}</span>
        <h2 className="text-3xl my-4 text-black-default font-bold">{title}</h2>
        <p className="mb-8">{description}</p>
        <div className="flex items-center">
          <img
            src={authorImage}
            alt="avatar"
            className="w-14 h-14 rounded-full"
          />
          <div className="flex flex-col ml-4">
            <span className="font-bold">{authorName}</span>
            <span className="text-sm">{dateString}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoCard
